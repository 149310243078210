<template>
  <footer class="bg-image py-5">
    <div class="container">
      <div class="row mx-auto">
        <div class="col-md-10 col-xl-8 mx-auto text-center">
          <!-- Logo -->
          <img class="mx-auto h-100px" src="@/assets/logo.png" alt="logo" />
          <h6 class="text-white">With Trust & Devotion</h6>
          <p class="mt-3 text-white">
            DEVALAYA is an innovative spiritual tech platform to fulfil the
            needs of Devotee & Pandit ji across the globe, We aim to bring local
            traditions into the global platform, so that the rituals and
            traditions are not lost in the backdrop of modern advancements and
            negligence.
          </p>
          <!-- Links -->
          <ul
            class="nav justify-content-between text-primary-hover mt-3 mt-md-0"
          >
            <li class="nav-item">
              <a
                target="_blank"
                class="nav-link py-1 text-white"
                href="https://www.devalayas.com/#/PrivacyPolicy"
                >Privacy policy</a
              >
            </li>
            <li class="nav-item">
              <a
                target="_blank"
                class="nav-link py-1 text-white"
                href="https://www.devalayas.com/#/TermsAndConditions"
                >Terms and conditions</a
              >
            </li>
            <li class="nav-item">
              <a
                target="_blank"
                class="nav-link py-1 text-white"
                href="https://www.devalayas.com/#/RefundPolicy"
                >Refund policy</a
              >
            </li>
            <li class="nav-item">
              <a
                target="_blank"
                class="nav-link py-1 text-white pe-0"
                href="https://www.devalayas.com/#/CancellationPolicy"
                >Cancellation policy</a
              >
            </li>
          </ul>
          <!-- Social media button -->
          <ul class="list-inline mt-3 mb-0">
            <li class="list-inline-item">
              <a
                target="_blank"
                class="btn btn-sm px-2 bg-facebook mb-0"
                href="https://www.facebook.com/devalayaonlinepujaapp/"
                ><i class="fab fa-fw fa-facebook-f"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                target="_blank"
                class="btn btn-sm shadow px-2 bg-twitter mb-0"
                href="https://twitter.com/devalayas_app"
                ><i class="fab fa-fw fa-twitter"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                target="_blank"
                class="btn btn-sm shadow px-2 bg-linkedin mb-0"
                href="http://www.linkedin.com/in/devalayaonlinepujaapp"
                ><i class="fab fa-fw fa-linkedin-in"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                target="_blank"
                class="btn btn-sm shadow px-2 bg-instagram mb-0"
                href="https://www.instagram.com/devalaya_app/"
                ><i class="fab fa-fw fa-instagram"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                target="_blank"
                class="btn btn-sm shadow px-2 bg-youtube mb-0"
                href="https://www.youtube.com/channel/UCRcWjnz7ybHWy2DfxlHlBpw"
                ><i class="fab fa-fw fa-youtube"></i
              ></a>
            </li>
          </ul>
          <!-- copyright text -->
          <div class="text-muted text-primary-hover mt-3">
            Copyrights
            <a
              target="_blank"
              href="https://www.devalayas.in/"
              class="text-muted"
              >©2023 Devalaya</a
            >. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Header",
};
</script>
<style>
.bg-image {
  background-color: #ef5b0c;
}
</style>
