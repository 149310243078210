<template>	
	<div v-if="$store.getters.user">
		<Header></Header>
	</div>
	
	<router-view />
	<Footer></Footer>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
	components: {
		Header, Footer
	},
	created() {
		if(sessionStorage.getItem('user')){
			this.$store.dispatch('setUser', JSON.parse(sessionStorage.getItem('user')));
			sessionStorage.removeItem('user')
		}
		if(sessionStorage.getItem('token')){
			this.$store.dispatch('setToken', sessionStorage.getItem('token'));
			sessionStorage.removeItem('token')
		}
		if(sessionStorage.getItem('token_type')){
			this.$store.dispatch('setTokenType', sessionStorage.getItem('token_type'));
			sessionStorage.removeItem('token_type')
		}
		if(sessionStorage.getItem('cart')){
			this.$store.dispatch('setCart', JSON.parse(sessionStorage.getItem('cart')));
			sessionStorage.removeItem('cart')
		}
		window.addEventListener("beforeunload",()=>{
            sessionStorage.setItem("user",  JSON.stringify(this.$store.getters.user))
            sessionStorage.setItem("token",  this.$store.getters.token)
            sessionStorage.setItem("token_type",  this.$store.getters.tokenType)
            sessionStorage.setItem("cart",  JSON.stringify(this.$store.getters.cart))
        })
	}
}

</script>