<template>
  <main style="background-color: #fff9c9">
    <section class="pt-4">
      <div class="container vstack gap-4">
        <div class="row">
          <div class="col-12">
            <div class="card border">
              <div style="text-align: center" class="card-header border-bottom">
                <h4 class="card-header-title">Profile</h4>
              </div>
              <!-- ##### -->
              <div class="card-body">
                <div class="flex-container">
                  <div class="flex-item-left">
                    <div
                      style="
                        padding: 5%;
                        background-color: aqua;
                        border-radius: 20px;
                      "
                    >
                      <h2>{{ vle_ref_count }}</h2>
                      <h5>Total Refered</h5>
                    </div>
                  </div>
                  <div class="flex-item-right">
                    <div
                      style="
                        padding: 5%;
                        background-color: rgb(255, 230, 0);
                        border-radius: 20px;
                      "
                    >
                      <h2>{{ pooja_request_count }}</h2>
                      <h5>Refered Puja's</h5>
                    </div>
                  </div>
                  <div class="flex-item-centre">
                    <div
                      style="
                        padding: 5%;
                        background-color: #17a2b891;
                        border-radius: 20px;
                      "
                    >
                      <h2>₹ {{ total_csc_ref_charges }}</h2>
                      <h5>Total Earnings</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="flex-container">
                  <div style="display: none">
                    <vue-qrcode
                      :value="yourLink"
                      ref="qrcode"
                      style="display: none"
                    ></vue-qrcode>
                    <img
                      :src="require(`@/assets/banners/devalayareferalpic.jpg`)"
                      alt="Photo"
                      ref="photo"
                      style="display: none; max-width: 100%; height: auto"
                    />
                  </div>
                  <button
                    class="flex-item-centre"
                    style="
                      background-color: rgba(151, 122, 186, 0.621);
                      border-radius: 20px;
                      padding-left: 10px;
                      padding-right: 10px;
                    "
                    @click="downloadMergedImage"
                  >
                    <vue-qrcode :value="yourLink" ref="qrcode"></vue-qrcode>
                    <h5>Download Referal Code</h5>
                  </button>
                  <!-- <div id="app">
                                            <textarea v-model="textToCopy" rows="4" cols="50" placeholder="Enter text to copy..."></textarea>
                                            <button @click="copyText">Copy Text</button>
                                            <div v-if="copied">Text copied to clipboard!</div>
                                        </div> -->
                  <button
                    class="flex-item-centre"
                    style="
                      background-color: rgba(30, 255, 83, 0.621);
                      border-radius: 20px;
                      padding-left: 10px;
                      padding-right: 10px;
                    "
                    @click="copyText"
                  >
                    <h4>Copy Your Referal :</h4>
                    <h2><i class="fa-regular fa-copy"></i></h2>
                    <h4>{{ textToCopy }}</h4>
                    <div v-if="copied">
                      <h6>Text copied to clipboard!</h6>
                    </div>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div style="border: solid" class="flex-item-centre">
                  <ul>
                    <li class="list-group-item mb-3">
                      <span>CSC ID:</span>
                      <span class="h6 fw-normal ms-1 mb-0">{{
                        user.csc_id
                      }}</span>
                    </li>

                    <li class="list-group-item mb-3">
                      <span>Mobile Number:</span>
                      <span class="h6 fw-normal ms-1 mb-0">{{
                        user.mob_no
                      }}</span>
                    </li>

                    <li class="list-group-item mb-3">
                      <span>DOB:</span>
                      <span class="h6 fw-normal ms-1 mb-0">{{ user.dob }}</span>
                    </li>

                    <li class="list-group-item mb-3">
                      <span>Name:</span>
                      <span class="h6 fw-normal ms-1 mb-0">{{
                        user.name
                      }}</span>
                    </li>

                    <li class="list-group-item mb-3">
                      <span>Email ID:</span>
                      <span class="h6 fw-normal ms-1 mb-0">{{
                        user.email
                      }}</span>
                    </li>

                    <li class="list-group-item mb-3">
                      <span>Address:</span>
                      <span class="h6 fw-normal ms-1 mb-0">{{
                        user.address
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import axios from "axios";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import html2canvas from "html2canvas";

export default {
  name: "Profile",
  components: {
    VueQrcode,
  },

  data() {
    return {
      textToCopy: "",
      copied: false,
      qrCodeSizeIncrease: 6.0, // 25% increase
      yourLink:
        "https://play.google.com/store/apps/details?id=com.devalaya.devotee",
      user: this.$store.getters.user,
      vleid: this.$store.getters.id,
      token: this.$store.getters.token,
      total_count: "",
      total_puja: "",
      uri: "",
      dynamicURL: "",
      apiUrl: "https://live.devalayas.com/api/v1/",
      id: {
        vle_user_id: "",
      },
      pooja_request_count: "0",
      vle_ref_count: "0",
      total_csc_ref_charges: "0",
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.referals();
    this.pooja();
    this.$nextTick(() => {
      this.$el.querySelector("button").style.display = "block";
    });
  },

  methods: {
    copyText() {
      const textarea = document.createElement("textarea");
      textarea.value = this.textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      this.copied = true;

      // Reset the 'copied' status after a short delay
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    async downloadMergedImage() {
      // Get the QR code element
      const qrcodeElement = this.$refs.qrcode.$el;

      // Get the photo element
      const photoElement = this.$refs.photo;

      // Calculate A4 dimensions (210mm x 297mm) in pixels
      const a4Width = 2480; // 210mm * 11.8 px/mm
      const a4Height = 3508; // 297mm * 11.8 px/mm

      // Calculate the new size of the QR code with a 25% increase
      const increasedQrCodeSize = {
        width: qrcodeElement.width * (1 + this.qrCodeSizeIncrease),
        height: qrcodeElement.height * (1 + this.qrCodeSizeIncrease),
      };

      // Create a new canvas with A4 dimensions
      const canvas = document.createElement("canvas");
      canvas.width = a4Width;
      canvas.height = a4Height;
      const context = canvas.getContext("2d");

      // Draw the photo on the canvas
      context.drawImage(photoElement, 0, 0, a4Width, a4Height);

      // Draw the QR code on the canvas at the bottom right with increased size
      const qrCodeX = a4Width - increasedQrCodeSize.width;
      const qrCodeY = a4Height - increasedQrCodeSize.height;
      context.drawImage(
        qrcodeElement,
        qrCodeX,
        qrCodeY,
        increasedQrCodeSize.width,
        increasedQrCodeSize.height
      );

      // Convert the canvas to a data URL
      const mergedDataURL = canvas.toDataURL("image/png");

      // Create a link element
      const a = document.createElement("a");
      a.href = mergedDataURL;
      a.download = "Devalaya_QR_Code.png";

      // Trigger a click on the link to start the download
      a.click();
    },
    //
    sendMessage() {
      const whatsappUrl = `https://api.whatsapp.com/send/?text=${encodeURIComponent(
        this.dynamicURL
      )}&type=custom_url&app_absent=0`;
      window.open(whatsappUrl, "_blank");
    },
    referals() {
      let vm = this;
      let loader = vm.$loading.show();
      vm.$store
        .dispatch("get", { uri: "vle/referral_count/" + this.vleid + "/" })
        .then((response) => {
          loader.hide();
          // console.log("referral_count",response);
          vm.pooja_request_count = response.data.pooja_request_count;
          vm.total_csc_ref_charges = response.data.total_csc_ref_charges;
          vm.vle_ref_count = response.data.vle_ref_count;
          if (this.vleid == "3299") {
            vm.vle_ref_count = parseInt(vm.vle_ref_count, 10) + 315;
          }
        })
        .catch((error) => {
          loader.hide();
          console.log(error);
        });
    },
    pooja() {
      let vm = this;
      let loader = this.$loading.show();
      let data = {
        vle: this.vleid,
      };
      this.$store
        .dispatch("post", { uri: "devotee/dynamic_link/", data: data })
        .then((response) => {
          loader.hide();
          // console.log('dymanic_link', response);
          this.dynamicURL = response.data.dynamic_link;
          this.textToCopy = response.data.dynamic_link;
          this.yourLink = response.data.dynamic_link;
        })
        .catch((errors) => {
          loader.hide();
          console.log(errors);
        });
    },
  },
};
</script>
<style>
* {
  box-sizing: border-box;
}

.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}

.flex-item-left {
  background-color: white;
  padding: 10px;
  flex: 50%;
  border-radius: 30px;
}

.flex-item-right {
  background-color: white;
  padding: 10px;
  flex: 50%;
  border-radius: 30px;
}
.flex-item-centre {
  padding: 10px;
  flex: 50%;
  border-radius: 30px;
  align-items: center;
}
.divcol {
  background-color: lightgrey;
  width: 300px;
  border: 15px solid green;
  padding: 50px;
  margin: 20px;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}
</style>
