<template>
  <header class="navbar-light header-sticky">
    <!-- Logo Nav START -->
    <nav class="navbar navbar-light navbar-expand-xl">
      <div class="container">
        <!-- Logo START -->
        <router-link to="/" class="navbar-brand">
          <img class="light-mode-item navbar-brand-item" src="@/assets/combine.png" alt="logo" />
        </router-link>
        <!-- Logo END -->

        <!-- Responsive navbar toggler -->
        <button class="navbar-toggler ms-auto me-3 p-0" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-animation">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>

        <div class="navbar-collapse collapse" id="navbarCollapse">
          <ul class="navbar-nav navbar-nav-scroll mx-auto">
            <li class="nav-item">
              <router-link class="nav-link custom-menu" to="/">HOME</router-link>
            </li>

            <li class="nav-item">
              <router-link class="nav-link custom-menu" to="/aboutpage">ABOUT US</router-link>
            </li>

            <li class="nav-item">
              <router-link class="nav-link custom-menu" to="/bookings">BOOKINGS</router-link>
            </li>

            <li class="nav-item">
              <router-link class="nav-link custom-menu" to="/suggest">SUGGEST TEMPLE</router-link>
            </li>
            <!-- 
                        <li class="nav-item">
                            <router-link class="nav-link custom-menu" to="/eventlist">EVENTS</router-link>
                        </li> -->

            <li class="nav-item">
              <a target="_blank" class="nav-link custom-menu" href="https://www.devalayas.com/#/">CONTACT US</a>
            </li>
          </ul>
        </div>

        <!-- Main navbar START -->
        <ul class="nav flex-row align-items-center list-unstyled ms-xl-auto">
          <li class="nav-item ms-3 dropdown">
            <!-- Avatar -->
            <a class="avatar avatar-lg p-0" href="#" id="profileDropdown" role="button" data-bs-auto-close="outside"
              data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
              <!-- <img class="avatar-img rounded-2" src="@/assets/avatar.png" alt="avatar" /> -->
              <img src="../assets/avatar.png" />
            </a>

            <ul class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3"
              aria-labelledby="profileDropdown">
              <!-- Profile info -->
              <li class="px-3 mb-3">
                <div class="d-flex align-items-center">
                  <!-- Avatar -->
                  <div class="avatar me-3">
                    <img class="avatar-img rounded-circle shadow" src="@/assets/avatar.png" alt="avatar" />
                  </div>
                  <div>
                    <router-link to="/profile" v-if="$store.getters.user.name == ''"
                      class="h6 mt-2 mt-sm-0">VLE-CSC</router-link>
                    <router-link to="/profile" v-else class="h6 mt-2 mt-sm-0" href="#">{{ $store.getters.user.name
                      }}</router-link>
                    <p v-if="$store.getters.user.mobile_number" class="small mt-2">
                      {{ $store.getters.user.mobile_number }}
                    </p>
                    <p v-else class="small mt-2">
                      {{ $store.getters.user.csc_id }}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <router-link class="dropdown-item bg-danger-soft-hover" to="/profile">
                  <i class="bi bi-info-circle fa-fw me-2"></i>
                  My Profile
                </router-link>
              </li>
              <li>
                <a class="dropdown-item bg-danger-soft-hover" href="#" @click.prevent="logout">
                  <i class="bi bi-power fa-fw me-2"></i>
                  Sign Out
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <button @click="viewCart()" style="
                background-color: white;
                min-width: 100px;
                min-height: 40px;
                border-radius: 20px;
                border-color: rgb(255, 2, 2);
                border: 0px;
                margin-left: 10px
              ">
          <i class="fa fa-shopping-cart" style="color: rgb(248, 49, 4)"></i>
          Cart (<span style="color: rgb(248, 49, 4)">{{ cartLength }}</span>)
        </button>
      </div>
    </nav>
    <!-- Logo Nav END -->
    <!-- Cart dialog -->
    <dialog id="cart_dialog" class="daialbox">
      <div class="box-form">
        <div class="right">
          <div style="overflow-y: scroll; padding: 5px">
            <div v-if="getCart.length == 0">
              <h3 class="product-title text-center">Your cart is empty!</h3>
            </div>
            <div class="cart-entry" v-for="pooja, key in getCart" :key='key' v-else>
              <!-- <img src="product-image.jpg" alt="Temple Image" class="product-image"> -->
              <div class="product-details">
                <h3 class="product-title">{{ pooja.pooja_name }}</h3>
                <span class="product-description">{{ pooja.name }}</span><br>
                <span class="product-description">{{ formattedDate(pooja.pooja_date) }}</span>
                <!-- <span class="product-price">₹100.00</span> -->
              </div>
              <button class="remove-btn" @click="removePooja(pooja)">Remove</button>
            </div>
          </div>
          <div style="text-align: center">
            <p style="padding: 10px">
              <!-- <span class="h6" style="color:gray; display: block">Total Payable Amount: ₹300</span> -->
            </p>
            <button v-if="getCart.length" class="button p-0 mb-0" style="vertical-align: middle"
              @click="confirmOrder()">
              Checkout <i class="bi bi-check2-circle"></i>
            </button>
          </div>
        </div>
        <i class="fas fa-times-circle" style="
            font-size: 20px;
            color: rgb(42, 38, 38);
            float: right;
            cursor: pointer;
            margin: 5px;
          " v-on:click="closeCart()"></i>
      </div>
    </dialog>
    <!-- end of Cart dialog -->
    <!-- Confirm order -->
    <dialog id="confirm_dialog" class="daialbox">
      <div class="box-form">
        <div class="right">
          <div ref="orderDetails" class="card shadow mb-4" v-if="response">
            <div class="card-header border-bottom text-center">
              <span class="fw-bold">ORDER DETAILS</span>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-borderless mb-3">
                <li class="list-group-item d-flex justify-content-between py-2">
                  <span class="h6 fw-light mb-0">Pooja Cost</span>
                  <span class="h6 fw-light mb-0">₹{{ payment?.original_cost
                    }}</span>
                </li>
                <div v-show="booking.prasadam">
                  <li class="list-group-item d-flex justify-content-between py-2">
                    <span class="h6 fw-light mb-0">Prasadam Delivery</span>
                    <span class="h6 fw-light mb-0">₹{{
                      payment?.delivery_charge
                    }}</span>
                  </li>
                </div>
                <li class="list-group-item d-flex justify-content-between py-2">
                  <span class="h6 fw-light mb-0">Convenience Fee</span>
                  <span class="h6 fw-light mb-0">₹{{
                    payment?.convenience_fee
                  }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between py-2">
                  <span class="h6 fw-light mb-0">Booking Charges</span>
                  <span class="h6 fw-light mb-0">₹{{
                    payment?.booking_charges
                  }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between py-2">
                  <span class="h6 fw-light mb-0">Tax</span>
                  <span class="h6 fw-light mb-0">₹{{ payment?.total_tax
                    }}</span>
                </li>

                <li class="list-group-item py-2">
                  <hr class="my-0">
                </li>
                <li class="list-group-item d-flex justify-content-between pb-0">
                  <div>
                    <span class="fw-bold mb-0">Total</span><br>
                    <small>Inc. of all taxes</small>
                  </div>
                  <span class="h5 fw-normal mb-0">₹{{ payment?.final_total
                    }}</span>
                </li>
              </ul>
              <!-- <button @click="razorpayPayment" class="btn btn-primary w-100 mb-0">
                Place Order
              </button> -->
            </div>
          </div>
          <div style="text-align: center">
            <button v-if="getCart.length" @click="razorpayPayment" class="button p-0 mb-0" style="vertical-align: middle">
              Place Order <i class="bi bi-check2-circle"></i>
            </button>
          </div>
        </div>
        <i class="fas fa-times-circle" style="
            font-size: 20px;
            color: rgb(42, 38, 38);
            float: right;
            cursor: pointer;
            margin: 5px;
          " v-on:click="closeConfirm()"></i>
      </div>
    </dialog>
    <!-- end of confirm order -->
  </header>
</template>

<script>
import moment from 'moment';
export default {
  name: "Header",
  data() {
    return {
      rz_key: "",
      response: true,
      booking: {
        pooja_id: '',
        pooja_name: '',
        pooja_price: 0,
        pooja_original_cost: '',
        prasad_delivery: false,
        booking_date: '',
        name: '',
        mobile_no: "",
        comment: " ",
        gotra: "",
        rashi: "",
        nakshatra: "",
        prasadam: false,
        prasadam_address: {
          street_address_1: "",
          street_address_2: "",
          area: "",
          city: "",
          state: "",
          pincode: ""
        }

      },
      payment: {
        original_cost: 0,
        delivery_charge: 0,
        convenience_fee: 0,
        booking_charges: 0,
        total_tax: 0,
        final_total: 0
      },
      payments: [],
      error: {
        booking_date: "",
        name: "",
        mobile_no: "",
        address_1: "",
        address_2: "",
        area: "",
        city: "",
        state: "",
        pincode: "",
      },
      errors: [],
      rz_key: '',
      order_id: ''
    }
  },
  computed: {
    cartLength() {
      return this.$store.getters.cart.length;
    },
    getCart() {
      return this.$store.getters.cart
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      window.open("https://stagingvle.cscestore.in/auth/logout");
      this.$router.push("/login");
    },
    viewCart() {
      // let vm = this;
      document.getElementById("cart_dialog").showModal();
      // vm.minutes = 3;
      // vm.seconds = 0;
      // vm.timer = null;
      // this.user.mobile_number = "";
      // this.phoneNumber = "";
      // this.NumberCode = "";
      // vm.otpSent = false;
      // vm.resendButton = false;
    },
    closeCart() {
      document.getElementById('cart_dialog').close();
    },
    formattedDate(pooja_date) {
      return moment(pooja_date).format('DD-MM-YYYY');
    },
    removePooja(pooja) {
      let filteredPooja = this.$store.getters.cart.filter(item =>
        item.pooja !== pooja.pooja ||
        item.devotee_number !== pooja.devotee_number ||
        item.pooja_date !== pooja.pooja_date
      );
      this.$store.dispatch('setCart', filteredPooja);
    },
    confirmOrder() {
      let vm = this;
      document.getElementById("cart_dialog").close();
      let payload = {
        uri: 'devotee/bulk_pooja_request/',
        data: {
          requests: this.$store.getters.cart
        }
      }
      let loader = this.$loading.show();
      this.$store.dispatch('post', payload)
        .then(response => {
          loader.hide();
          this.payments = response.data;
          this.calculateFinalCost();
          // vm.request = false;
          vm.response = true;
          vm.getRZKey();
          // vm.order_id = response.data.order_id;
        })
        .catch(errors => {
          loader.hide();
          alert(errors.response.data.errors[0].message[0])
        })

      document.getElementById("confirm_dialog").showModal();
    },
    closeConfirm() {
      document.getElementById("confirm_dialog").close();
    },
    calculateFinalCost() {
      if(this.payments.length){
        this.payment.id = this.payments[0].id;
        this.order_id = this.payments[0].order_id;
      }
      this.payment.original_cost = 0;
      this.payment.delivery_charge = 0;
      this.payment.convenience_fee = 0;
      this.payment.booking_charges = 0;
      this.payment.total_tax = 0;
      this.payment.final_total = 0;
      this.payments.forEach(obj => {
        this.payment.payment_order_id = obj.payment_order_id;
        this.payment.original_cost += parseFloat(obj.payment_data.original_cost);
        this.payment.delivery_charge += parseFloat(obj.payment_data.delivery_charge);
        this.payment.convenience_fee += parseFloat(obj.payment_data.convenience_fee);
        this.payment.booking_charges += parseFloat(obj.payment_data.booking_charges);
        this.payment.total_tax += parseFloat(obj.payment_data.total_tax);
        this.payment.final_total += parseFloat(obj.payment_data.final_total);
      });
    },
    getRZKey() {
      let vm = this;
      this.$store.dispatch('get', { uri: 'devotee/payment_key/' })
        .then(response => {
          vm.rz_key = response.data.key;
        })
        .catch(errors => {
          console.log(errors)
        })
    },
    razorpayPayment() {
      this.closeConfirm();
      let vm = this;
      var options = {
        key: vm.rz_key,
        amount: (this.payment.final_total * 100),
        currency: "INR",
        name: "Devalaya",
        description: "Payment towards Pooja",
        image: "https://cdn.shopify.com/s/files/1/0735/5895/0166/files/unnamed_copy_ac3ece77-8a3a-44b7-b0f2-820c39455044.jpg?v=1679241399&width=500",
        order_id: this.payment.payment_order_id,
        handler: function (response) {
          vm.placeOrder(response);
        },
        prefill: {
          name: this.devotee_name,
          email: '',
          contact: this.devotee_mobile
        },
        notes: {
          address: "Devalaya",
        },
        theme: {
          color: "#df3002",
        },
      };
      var rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert(JSON.stringify(response))
      });
      rzp1.open();
    },
    placeOrder(rz_response) {
      let vm = this;
      let data = {
        razorpay_response: rz_response,
        request_id: vm.payment.id
      }
      let loader = this.$loading.show();
      this.$store.dispatch('post', { uri: 'devotee/pooja_request/payment/', data: data })
        .then(response => {
          loader.hide();
          this.$store.dispatch('setCart',[]);
          vm.$router.push({
            name: 'order',
            params: { payment_id: rz_response.razorpay_payment_id, order_id: vm.order_id },
          });          
        })
        .catch(errors => {
          loader.hide();
          console.log(errors)
        })
    },

  },
};
</script>

<style>
.custom-menu {
  font-size: auto;
  color: rgb(5, 5, 5);
  font-family: commanders;
}

/* css for cart items */
.login-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #000000;
  border: none;
  border-radius: 5px;
}

.login-button:hover {
  background-color: #f0f0f0;
}

.custom-menu {
  font-size: auto;
  color: rgb(5, 5, 5);
  font-family: commanders;
}

.cancel-symbol {
  color: red;
  /* Adjust the color as needed */
  font-size: 30px;
  /* Adjust the font size as needed */
  cursor: pointer;
  /* Optional: Change cursor on hover to indicate it's clickable */
  float: right;
}

.box-form {
  margin: 0 auto;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex: 1 1 100%;
  align-items: stretch;
  justify-content: space-between;
  /* box-shadow: 0 0 20px 6px #090b6f85; */
}

@media (max-width: 980px) {
  .box-form {
    flex-flow: wrap;
    text-align: center;
    align-content: center;
    align-items: center;
  }
}

.box-form div {
  height: auto;
}

.box-form .left {
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://i.pinimg.com/736x/5d/73/ea/5d73eaabb25e3805de1f8cdea7df4a42--tumblr-backgrounds-iphone-phone-wallpapers-iphone-wallaper-tumblr.jpg");
  overflow: hidden;
}

.box-form .left .overlay {
  padding: 30px;
  width: 100%;
  height: 100%;
  background: #5961f9ad;
  overflow: hidden;
  box-sizing: border-box;
}

.box-form .left .overlay h1 {
  font-size: 10vmax;
  line-height: 1;
  font-weight: 900;
  margin-top: 40px;
  margin-bottom: 20px;
}

.box-form .left .overlay span p {
  margin-top: 30px;
  font-weight: 900;
}

.box-form .left .overlay span a {
  background: #3b5998;
  color: #ffffff;
  margin-top: 10px;
  padding: 14px 50px;
  border-radius: 100px;
  display: inline-block;
}

.box-form .left .overlay span a:last-child {
  background: #1dcaff;
  margin-left: 30px;
}

.box-form .right {
  padding: 20px;
  /* overflow: hidden; */
}

@media (max-width: 980px) {
  .box-form .right {
    width: 100%;
  }
}

.box-form .right h5 {
  font-size: 6vmax;
  line-height: 0;
}

.box-form .right p {
  font-size: 14px;
  color: #b0b3b9;
}

.box-form .right .inputs {
  overflow: hidden;
}

.box-form .right input {
  width: 100%;
  padding: 10px;
  margin-top: 25px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 2px solid #b0b3b9;
}

.box-form .right .remember-me--forget-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-form .right .remember-me--forget-password input {
  margin: 0;
  margin-right: 7px;
  width: auto;
}

.box-form .right button {
  color: #fff;
  font-size: 16px;
  padding: 12px 35px;
  border-radius: 50px;
  display: inline-block;
  border: 0;
  outline: 0;
  background: linear-gradient(140deg, #ff9800, #ff5757);
}

.daialbox {
  border: 1px solid #ffffff00;
  border-radius: 8px;
  padding: 0%;
  background-color: transparent;
}

/*  */
.product-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  width: 100%;
}

.product-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 15px;
}

.product-details {
  flex: 1 !important;
}

.product-name {
  font-size: 16px !important;
  margin-bottom: 5px !important;
}

.product-price {
  font-size: 14px !important;
  color: #333 !important;
  font-weight: bold !important;
}

.remove-btn {
  background-color: red !important;
  color: white !important;
  border: none !important;
  padding: 5px 10px !important;
  cursor: pointer !important;
  border-radius: 4px !important;
}

.remove-btn:hover {
  background-color: darkred !important;
}
</style>
