import { createStore } from "vuex";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import axios from 'axios';

export default createStore({
	state() {
		return {
			apiUrl: 'https://live.devalayas.com/api/v1/',
			// apiUrl: 'https://beta.devalayas.com/api/v1/',
			user: null,
			token: "",
			token_type: "Bearer",
			id: "",
			cart: []
		}
	},

	getters: {
		user(state) {
			return state.user;
		},
		token(state) {
			return state.token;
		},
		id(state) {
			return state.id;
		},
		tokenType(state) {
			return state.token_type;
		},
		apiUrl(state) {
			return state.apiUrl;
		},
		cart(state) {
			return state.cart;
		}
	},

	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		setToken(state, token) {
			state.token = token;
		},
		setvleid(state, id) {
			state.id = id;
		},
		setTokenType(state, token_type) {
			state.token_type = token_type;
		},
		addToCart(state, pooja) {
			const exists = state.cart.some(item =>
				item.pooja === pooja.pooja &&
				item.devotee_number === pooja.devotee_number &&
				item.pooja_date === pooja.pooja_date);
			if (!exists) {
				state.cart.push(pooja);
				createToast({
					title: 'Success',
					description: "Pooja added successfully."
				},
					{
						showIcon: true,
						hideProgressBar: true,
						type: 'success',
						position: 'top-right'
					})
			} else {
				alert('Pooja already exists!');
			}
		},
		setCart(state, cart) {
			state.cart = cart;
		}
	},

	actions: {
		addToCart(context, payload) {
			context.commit('addToCart', payload);
		},
		async setCart(context, payload) {
			context.commit('setCart', payload);
		},
		async setUser(context, payload) {
			await context.commit('setUser', payload);
		},

		async setToken(context, payload) {
			await context.commit('setToken', payload);
		},

		async setvleid(context, payload) {
			await context.commit('setvleid', payload);
		},

		async setTokenType(context, payload) {
			await context.commit('setTokenType', payload);
		},

		async logout(context) {
			await context.commit('setUser', null);
			await context.commit('setToken', "");
			await context.commit('setvleid', "");
			await context.commit('setTokenType', "Bearer");
		},

		auth(context, payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl + payload.uri, payload.data)
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		get(context, payload) {
			return new Promise((resolve, reject) => {
				axios.get(this.state.apiUrl + payload.uri, {
					headers: {
						'Authorization': this.state.token_type + ' ' + context.getters.token
					}
				}).then(function (response) {
					resolve(response);
				})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		post(context, payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl + payload.uri, payload.data, {
					headers: {
						'Authorization': this.state.token_type + ' ' + context.getters.token
					}
				}).then(function (response) {
					resolve(response);
				})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		put(context, payload) {
			return new Promise((resolve, reject) => {
				axios.put(this.state.apiUrl + payload.uri, payload.data, {
					headers: {
						'Authorization': this.state.token_type + ' ' + context.getters.token
					}
				}).then(function (response) {
					resolve(response);
				})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		async error(context, description) {
			await createToast({
				title: 'Error',
				description: description || "The given data was invalid."
			},
				{
					showIcon: true,
					hideProgressBar: true,
					type: 'danger',
					position: 'top-right'
				})
		},

		async success(context, description) {
			await createToast({
				title: 'Success',
				description: description || "Data is successfuly submited."
			},
				{
					showIcon: true,
					hideProgressBar: true,
					type: 'success',
					position: 'top-right'
				})
		},
	}

})
