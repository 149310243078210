import { createRouter, createWebHashHistory } from "vue-router";
import store from '../store/index'

import Login from '../views/Login.vue'
import Home from "../views/Home.vue";
import Temple from "../views/Temple.vue";
import Bookings from "../views/Bookings.vue";
import Order from "@/views/Order.vue";
import About from "@/views/About.vue"
import Profile from "@/views/Profile.vue"
import SuggestTemple from "@/views/SuggestTemple.vue"
import Events from "@/views/Events.vue"
import Eventlist from "@/views/Eventlist.vue"

const router = createRouter({
	history: createWebHashHistory(),
	routes: [
		{
			path: '/login',
			name: 'login',
			component: Login,
			meta: { unAuth: true }
		},		
		{
			path: '/suggest',
			name: 'suggest',
			component: SuggestTemple,
			meta: { auth: true }
		},
		{
			path: '/',
			name: 'home',
			component: Home,
			meta: { auth: true }
		},
		{
			path:'/temples/:id', 
			name: 'temples.view',
			component: Temple, 
			meta: { auth: true }
		},
		{
			path: '/bookings',
			name: 'bookings',
			component: Bookings,
			meta: { auth: true }
		},
		{
			path: '/order/:payment_id/:order_id',
			name: 'order',
			component: Order,
			meta: { auth: true },
		},
		{
			path: '/aboutpage',
			name: 'aboutpage',
			component: About,
			meta: { auth: true },
		},
		{
			path: '/profile',
			name: 'profile',
			component: Profile,
			meta: { auth: true },
		},
		{
			// path: '/events/:id/search/:name',
			path: '/search/:name',
			name: 'events',
			component: Events,
			meta: { auth: true },
		},
		{
			path: '/eventlist',
			name: 'eventlist',
			component: Eventlist,
			meta: { auth: true }
		},
	]
})

router.beforeEach(function (to, _, next) {
	if (to.meta.auth && !store.getters.user) {
		next("login");
	} else if (to.meta.unAuth && store.getters.user) {
		next("/");
	} else {
		next();
	}
});

export default router
