<template>
	<main>
        <section class="pt-5">
			<div class="container">
				<div class="row">
					<div class="col-md-10 col-xl-8 mx-auto">

						<div class="card shadow1 p-5">
							<!-- Image -->							
							<div v-if="!request">
								<div class="carousel-inner" >
									<div class="carousel-item active ba-1" style="border-radius: 5px; background-image: url(https://a0.anyrgb.com/pngimg/48/1054/good-looking-gold-label-looking-gold-border-gold-frame-gold-coin-pink-ribbon-silk-good-digital.png);">
    	<img style="width: 19%; float:inline-start;" class="light-mode-item navbar-brand-item" src="@/assets/logo.png" alt="logo" />
	   <div class="pm-certificate-border col-xs-12">    

      <div class="row pm-certificate-body">
        
        <div class="pm-certificate-block">   
			<br>               
			<br>               
			<br>               
			<br>              
            <div class="col-xs-12">
              <div class="row">
                <div class="col-xs-12">
					<h4>ಮೆಚ್ಚುಗೆಯ  ಪ್ರಮಾಣಪತ್ರ</h4>
					<h4>ಹೆಮ್ಮೆಯಿಂದ  ಸಲ್ಲಿಸಲಾಗಿದೆ</h4>
				</div>
					<br>
					<br>
					<br>
					<br>
                  <h4 style="color: blue;">{{ devotee_Name }}</h4>
				  <br>
				  <br>
				  <h5 style="color: rgb(176, 164, 0);">ಕನ್ನಡವೇ ಸತ್ಯ ಕನ್ನಡವೇ ನಿತ್ಯ</h5>
				  <h5 style="color: rgb(187, 34, 34);">ಹಚ್ಚೋಣ ಕನ್ನಡದ ದೀಪ</h5>
				  <br>
				  <br>
				  <div style="align-items: center;">
					<div class="pm-course-title underline col-xs-8 text-center" style="width: 100%; float: left;"></div>
					<h6 >ತಾಯಿ ಭುವನೇಶ್ವರಿ ನಿಮ್ಮನ್ನು ಹರಸಲಿ. ಕನ್ನಡ ಎಲ್ಲೆಡೆ ಬೆಳಗಲಿ.</h6>
				  </div>
				  				  			  
                </div>
            </div>
        </div>             
      </div>
    </div>
  </div>						
								
								</div>

							    
							
							</div>


							<div class="text-center" v-if="request">
								<img :src="image" class="temple card-img-middle" alt="Card image">	
							</div>		
							
							

							<!-- Card body -->
							<div class="card-body text-center p-4" v-if="request">
								<!-- Title -->
								<h5 >
									Congratulations your puja is booked Successfully. May God's grace be upon you. Devalaya hopes that this pooja will please you and fulfill your wishes.
								</h5>
								<!-- <h6 class="card-title fs-3">Congratulations your puja is booked Successfully. May God's grace be upon you. Devalaya hopes that this pooja will please you and fulfill your wishes.</h6> -->
								<!-- <p class="lead mb-3">Pooja has been booked in {{ templeName }}</p> -->

								<!-- Second title -->
								<h6 class="text-primary mb-4">
									Thank you for placing the order. Please find the order details below:
								</h6>
								<div class="table-responsive">
									<table class="table table-sm table-primary-sm table-bordered">
										<tbody>
											<tr>
												<td>Temple </td><td>{{ templeName }}</td>
											</tr>
											<tr>
												<td>Order ID</td><td>{{ order_id }}</td>
											</tr>
										</tbody>
									</table>
								</div>								
								<!-- Button -->
								<div class="d-sm-flex justify-content-sm-center d-grid">
									<a href="#" @click.prevent="this.$router.push('/bookings')" class="btn btn-primary mb-0">OK</a>
								</div>
							</div>
							<div class="card-body text-center p-4" v-if="!request">								
								<br>
								<br>
								<br>
								<div class="d-sm-flex justify-content-sm-center d-grid">
									<a href="#" @click.prevent="this.$router.push('/bookings')" class="btn btn-primary mb-0">OK</a>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>			
		</section>
	</main>
</template>
<script>

export default{
    mounted(){
    this.payment_id = this.$route.params.payment_id;
		this.order_id = this.$route.params.order_id;
		window.scrollTo(0, 0);
		this.getBookings();	
    },

    data(){
        return{
      payment_id : '',
			order_id : '',
			temples: [],
			temple: [],
			image : '',
			templeName : '',
			request : false,
			templeId : '',
			devotee_Name : '',
        }
    },

	methods: {
        getBookings() {
            let vm = this;
            let uri = 'devotee/pooja_request/list/?search=' + this.order_id ;
            this.$store.dispatch('get', { uri: uri })
            .then(response => {				
                // console.log(response.data.results);
                vm.temples = response.data.results;
                vm.image = vm.temples[0].temple.images[0].image;
                vm.templeName = vm.temples[0].temple.name;
				vm.templeId = vm.temples[0].temple.id;				
				vm.request=true;
				if(vm.templeId == "1159"){
					vm.request=false;
					vm.devotee_Name = vm.temples[0].name;
				}
            })
            .catch(errors => {
                console.warn(errors);
            })
        },    	
    }
	
}
</script>
<style>
.shadow1 {
  -webkit-box-shadow: 0px 0px 40px rgba(29, 58, 83, 0.1) !important;
          box-shadow: 0px 0px 40px rgba(29, 58, 83, 0.1) !important;
          background-image:url("/src/assets/celebrations.gif");
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Pinyon+Script|Rochester');

.cursive {
  font-family: 'Pinyon Script', cursive;
}

.sans {
  font-family: 'Open Sans', sans-serif;
}

.bold {
  font-weight: bold;
}

.block {
  display: block;
}

.underline {
  border-bottom: 1px solid #777;
  padding: 5px;
  margin-bottom: 15px;
}

.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}

.pm-empty-space {
  height: 40px;
  width: 100%;
}

body {
  padding: 20px 0;
  background: #ccc;
}

.pm-certificate-container {
  position: relative;
  width: 800px;
  height: 600px;
  background-color: #618597;
  padding: 30px;
  color: #333;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);

  .outer-border {
    width: 794px;
    height: 594px;
    position: absolute;
    left: 50%;
    margin-left: -397px;
    top: 50%;
    margin-top:-297px;
    border: 2px solid #fff;
  }
  
  .inner-border {
    width: 730px;
    height: 530px;
    position: absolute;
    left: 50%;
    margin-left: -365px;
    top: 50%;
    margin-top:-265px;
    border: 2px solid #fff;
  }

  .pm-certificate-border {
    position: relative;
    width: 720px;
    height: 520px;
    padding: 0;
    border: 1px solid #E1E5F0;
    background-color: rgba(255, 255, 255, 1);
    background-image: none;
    left: 50%;
    margin-left: -360px;
    top: 50%;
    margin-top: -260px;

    .pm-certificate-block {
      width: 650px;
      height: 200px;
      position: relative;
      left: 50%;
      margin-left: -325px;
      top: 70px;
      margin-top: 0;
    }

    .pm-certificate-header {
      margin-bottom: 10px;
    }

    .pm-certificate-title {
      position: relative;
      top: 40px;

      h2 {
        font-size: 34px !important;
      }
    }

    .pm-certificate-body {
      padding: 20px;

      .pm-name-text {
        font-size: 20px;
      }
    }

    .pm-earned {
      margin: 15px 0 20px;
      .pm-earned-text {
        font-size: 20px;
      }
      .pm-credits-text {
        font-size: 15px;
      }
    }

    .pm-course-title {
      .pm-earned-text {
        font-size: 20px;
      }
      .pm-credits-text {
        font-size: 15px;
      }
    }

    .pm-certified {
      font-size: 12px;

      .underline {
        margin-bottom: 5px;
      }
    }

    .pm-certificate-footer {
      width: 650px;
      height: 100px;
      position: relative;
      left: 50%;
      margin-left: -325px;
      bottom: -105px;
    }
  }
}


</style>